import _mailer from "./mailer";
import _shared from "./shared";
import _smtpPool from "./smtp-pool";
import _smtpTransport from "./smtp-transport";
import _sendmailTransport from "./sendmail-transport";
import _streamTransport from "./stream-transport";
import _jsonTransport from "./json-transport";
import _sesTransport from "./ses-transport";
import _fetch from "./fetch";
import _package from "../package.json";
import _process from "process";
import _buffer from "buffer";
var exports = {};
var Buffer = _buffer.Buffer;
var process = _process;
const Mailer = _mailer;
const shared = _shared;
const SMTPPool = _smtpPool;
const SMTPTransport = _smtpTransport;
const SendmailTransport = _sendmailTransport;
const StreamTransport = _streamTransport;
const JSONTransport = _jsonTransport;
const SESTransport = _sesTransport;
const nmfetch = _fetch;
const packageData = _package;
const ETHEREAL_API = (process.env.ETHEREAL_API || "https://api.nodemailer.com").replace(/\/+$/, "");
const ETHEREAL_WEB = (process.env.ETHEREAL_WEB || "https://ethereal.email").replace(/\/+$/, "");
const ETHEREAL_CACHE = ["true", "yes", "y", "1"].includes((process.env.ETHEREAL_CACHE || "yes").toString().trim().toLowerCase());
let testAccount = false;
exports.createTransport = function (transporter, defaults) {
  let urlConfig;
  let options;
  let mailer;
  if (
  // provided transporter is a configuration object, not transporter plugin
  typeof transporter === "object" && typeof transporter.send !== "function" ||
  // provided transporter looks like a connection url
  typeof transporter === "string" && /^(smtps?|direct):/i.test(transporter)) {
    if (urlConfig = typeof transporter === "string" ? transporter : transporter.url) {
      // parse a configuration URL into configuration options
      options = shared.parseConnectionUrl(urlConfig);
    } else {
      options = transporter;
    }
    if (options.pool) {
      transporter = new SMTPPool(options);
    } else if (options.sendmail) {
      transporter = new SendmailTransport(options);
    } else if (options.streamTransport) {
      transporter = new StreamTransport(options);
    } else if (options.jsonTransport) {
      transporter = new JSONTransport(options);
    } else if (options.SES) {
      transporter = new SESTransport(options);
    } else {
      transporter = new SMTPTransport(options);
    }
  }
  mailer = new Mailer(transporter, options, defaults);
  return mailer;
};
exports.createTestAccount = function (apiUrl, callback) {
  let promise;
  if (!callback && typeof apiUrl === "function") {
    callback = apiUrl;
    apiUrl = false;
  }
  if (!callback) {
    promise = new Promise((resolve, reject) => {
      callback = shared.callbackPromise(resolve, reject);
    });
  }
  if (ETHEREAL_CACHE && testAccount) {
    process.nextTick(() => callback(null, testAccount));
    return promise;
  }
  apiUrl = apiUrl || ETHEREAL_API;
  let chunks = [];
  let chunklen = 0;
  let req = nmfetch(apiUrl + "/user", {
    contentType: "application/json",
    method: "POST",
    body: Buffer.from(JSON.stringify({
      requestor: packageData.name,
      version: packageData.version
    }))
  });
  req.on("readable", () => {
    let chunk;
    while ((chunk = req.read()) !== null) {
      chunks.push(chunk);
      chunklen += chunk.length;
    }
  });
  req.once("error", err => callback(err));
  req.once("end", () => {
    let res = Buffer.concat(chunks, chunklen);
    let data;
    let err;
    try {
      data = JSON.parse(res.toString());
    } catch (E) {
      err = E;
    }
    if (err) {
      return callback(err);
    }
    if (data.status !== "success" || data.error) {
      return callback(new Error(data.error || "Request failed"));
    }
    delete data.status;
    testAccount = data;
    callback(null, testAccount);
  });
  return promise;
};
exports.getTestMessageUrl = function (info) {
  if (!info || !info.response) {
    return false;
  }
  let infoProps = new Map();
  info.response.replace(/\[([^\]]+)\]$/, (m, props) => {
    props.replace(/\b([A-Z0-9]+)=([^\s]+)/g, (m, key, value) => {
      infoProps.set(key, value);
    });
  });
  if (infoProps.has("STATUS") && infoProps.has("MSGID")) {
    return (testAccount.web || ETHEREAL_WEB) + "/message/" + infoProps.get("MSGID");
  }
  return false;
};
export default exports;
export const createTransport = exports.createTransport,
  createTestAccount = exports.createTestAccount,
  getTestMessageUrl = exports.getTestMessageUrl;